import "./Pageone.css";
import React, { useEffect } from "react";

const Pageone = () => {

    const openMail = () => {
        window.open('mailto:hamoudyazen@gmail.com?body=Hey%20there%20%2C%20i%20came%20from%20your%20Portfolio%20!', '_blank');
    };

    const openLinkedin = () => {
        window.open('https://www.linkedin.com/in/hamoudyazen/', '_blank');
    };

    const openCV = () => {
        window.open('https://github.com/hamoudyazen', '_blank');
    };




    useEffect(() => {
        // Intersection Observer for .card elements
        const cards = document.querySelectorAll(".card");

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classNameList.add("show"); // Add the "show" className when visible
                    observer.unobserve(entry.target); // Stop observing once it's visible
                }
            });
        }, { threshold: 1 });

        cards.forEach((card) => {
            observer.observe(card);
        });

        // Hover effect for .gridItem1
        const gridItem1 = document.querySelector('.gridItem1');


        gridItem1.addEventListener('click', () => {
            gridItem1.style.animation = "gridhover 1s forwards ease-in";
        });



    }, []);



    return (
        <>

            <div className="fullContainer">
                <div className="gridContainer">

                    <div className="gridItem1">
                        <div className="gridItem1Card">
                            <div className="gridItem1CardHeader">
                                <p>Yazen Hamoud</p>

                            </div>
                            <p className="scrollingTextDiv1">
                                UX/UI designer & front-end web developer
                            </p>
                            <div className="gridItem1CardHeader">
                                <p>See My Services</p>
                                <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M50.0001 0C53.0682 0 55.5556 1.9188 55.5556 4.28571V85.3674L90.5165 58.3983C92.6861 56.7246 96.2031 56.7246 98.3728 58.3983C100.542 60.072 100.542 62.7851 98.3728 64.4589L53.9282 98.7446C52.8868 99.5486 51.4734 100 50.0001 100C48.5267 100 47.1134 99.5486 46.0719 98.7446L1.62717 64.4589C-0.54239 62.7851 -0.54239 60.072 1.62717 58.3983C3.79673 56.7246 7.31437 56.7246 9.48393 58.3983L44.4445 85.3674V4.28571C44.4445 1.9188 46.9319 0 50.0001 0Z"
                                        fill="#F3F2F8" />
                                </svg>
                            </div>
                        </div>
                    </div>





                    <div className="gridItem2">
                        <div className="gridItem2Card" onClick={openMail}>
                            <div className="gridItem1CardHeader2">
                                <p></p>
                                <p></p>
                            </div>
                            <p className="scrollingTextDiv2">
                                LET'S TALK BUSINESS ! GET IN TOUCH.
                            </p>
                            <div className="gridItem1CardHeader2">
                                <p>Contact Me</p>
                                <svg width="30" height="30" viewBox="0 0 103 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M102.395 43.1078C101.988 42.6709 101.371 42.2577 100.537 41.8857L7.92406 0.661305C4.58935 -0.822212 2.43469 0.896223 3.13642 4.48007L9.10012 34.9715C9.80185 38.5553 9.56813 44.3757 8.58493 47.9057L0.42583 77.1243C-0.0404697 78.7994 0.165965 80.1038 0.884835 80.8747C1.67836 81.7256 3.09024 81.9272 4.90179 81.2772L100.335 46.8462C102.921 45.9108 103.617 44.4185 102.395 43.1034L102.395 43.1078ZM15.4014 33.556L10.5692 8.83936L81.7329 40.5217L15.9098 38.7956C15.8633 36.9102 15.7056 35.1028 15.4014 33.556ZM8.17916 73.3242L14.7556 49.7597C15.1433 48.3674 15.4284 46.7458 15.6277 45.046L81.7559 46.7827L8.17916 73.3242Z"
                                        fill="black" />
                                </svg>
                            </div>
                        </div>
                    </div>







                    <div className="gridItem3">
                        <div className="gridItem3Card" onClick={openCV}>
                            <div className="gridItem1CardHeader3">
                                <p></p>
                                <p></p>
                            </div>
                            <p className="scrollingTextDiv3">
                                VIEW MY PROJECTS.
                            </p>
                            <div className="gridItem1CardHeader3">
                                <p>Work</p>
                                <svg width="30" height="30" viewBox="0 0 110 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M82.7778 62.8095H55M27.2222 34.2381L43.8889 48.5238L27.2222 62.8095M5 67.3821V23.9535C5 17.5529 5 14.3502 6.21106 11.9055C7.27628 9.75509 8.97483 8.008 11.0656 6.91234C13.4423 5.66669 16.5561 5.66669 22.7789 5.66669H87.2233C93.4461 5.66669 96.5533 5.66669 98.93 6.91234C101.021 8.008 102.725 9.75509 103.79 11.9055C105 14.3478 105 17.5466 105 23.9347V67.3987C105 73.7867 105 76.981 103.79 79.4233C102.725 81.5735 101.021 83.3267 98.93 84.4221C96.5556 85.6667 93.45 85.6667 87.2394 85.6667H22.7606C16.5499 85.6667 13.44 85.6667 11.0656 84.4221C8.97483 83.3267 7.27628 81.5735 6.21106 79.4233C5 76.9787 5 73.7827 5 67.3821Z"
                                        stroke="black" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>






                    <div className="gridItem4">
                        <div className="gridItem4Card" onClick={openLinkedin}>
                            <div className="gridItem1CardHeader4">
                                <p></p>
                                <p></p>
                            </div>
                            <p className="scrollingTextDiv4">
                                Learn More About Me.
                            </p>
                            <div className="gridItem1CardHeader4">
                                <p>About Me</p>
                                <svg width="20" height="30" viewBox="0 0 90 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M45 6.97674C32.9744 6.97674 23.2258 14.7857 23.2258 24.4186C23.2258 34.0515 32.9744 41.8605 45 41.8605C57.0257 41.8605 66.7742 34.0515 66.7742 24.4186C66.7742 14.7857 57.0257 6.97674 45 6.97674ZM14.5161 24.4186C14.5161 10.9326 28.1642 0 45 0C61.8358 0 75.4839 10.9326 75.4839 24.4186C75.4839 37.9047 61.8358 48.8372 45 48.8372C28.1642 48.8372 14.5161 37.9047 14.5161 24.4186Z"
                                        fill="#F3F2F8" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 80.5647C0 66.8954 13.8338 55.814 30.8986 55.814H59.1016C76.1661 55.814 90 66.8954 90 80.5647C90 91.2986 79.1373 100 65.7372 100H24.2627C10.8628 100 0 91.2986 0 80.5647ZM30.8986 62.7907C18.644 62.7907 8.70968 70.7484 8.70968 80.5647C8.70968 87.4456 15.673 93.0233 24.2627 93.0233H65.7372C74.3272 93.0233 81.2903 87.4456 81.2903 80.5647C81.2903 70.7484 71.3561 62.7907 59.1016 62.7907H30.8986Z"
                                        fill="#F3F2F8" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pageone;
