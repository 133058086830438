import "./Section6.css"

import React from 'react'

const Section6 = () => {
    const openMail = () => {
        window.open('mailto:hamoudyazen@gmail.com?body=Hey%20there%20%2C%20i%20came%20from%20your%20Portfolio%20!', '_blank');
    };

    const openLinkedin = () => {
        window.open('https://www.linkedin.com/in/hamoudyazen/', '_blank');
    };

    const openCV = () => {
        window.open('https://docs.google.com/document/d/1PxVFOxXdYkNuDwmKzKTHRMNepfwT6CE2ktFxqYFmIrI/edit?usp=sharing', '_blank');
    };

    return (
        <>
            <div className="FlexGrid9">

                <div className="FlexContainer9">
                    <div className="section2CardHeader9">
                        <p>GET IN TOUCH!</p>
                    </div>

                    <div className="section2CardContentr9">
                        <h1>Do you have a question, an idea, or a project you need help with? Contact Me!</h1>
                    </div>

                    <div className="section2CardFooter9">
                        <button onClick={openLinkedin} className="ButtonFooterContact"><svg width="50" height="50" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M85.2083 85.2081H70.3917V61.9999C70.3917 56.4666 70.2917 49.3416 62.6833 49.3416C54.9667 49.3416 53.7833 55.3749 53.7833 61.5916V85.2081H38.9667V37.4833H53.1917V44.0083H53.3917C56.2917 39.0583 61.6917 36.0917 67.425 36.3C82.4417 36.3 85.2167 46.1749 85.2167 59.0332L85.2083 85.1998V85.2081ZM22.25 30.9583C19.9739 30.9474 17.7941 30.039 16.1839 28.4303C14.5737 26.8217 13.6631 24.6427 13.65 22.3667C13.65 17.6501 17.5333 13.7667 22.25 13.7667C26.9667 13.7667 30.8417 17.6501 30.85 22.3667C30.85 27.0834 26.9667 30.9583 22.25 30.9583ZM29.6583 85.2081H14.825V37.4833H29.6583V85.2081ZM92.5833 0.00012386H7.375C5.44292 -0.011098 3.58448 0.740618 2.20353 2.09192C0.822574 3.44322 0.0307055 5.2849 0 7.21676V92.7831C0.0307055 94.715 0.822574 96.5567 2.20353 97.908C3.58448 99.2593 5.44292 100.011 7.375 99.9998H92.5917C94.5282 100.015 96.3927 99.2662 97.7799 97.9149C99.1671 96.5636 99.9649 94.7194 100 92.7831V7.20843C99.9649 5.27366 99.1665 3.43118 97.7788 2.08252C96.3912 0.733861 94.5267 -0.0116521 92.5917 0.00845656L92.5833 0.00012386Z" fill="#D4C74F" />
                        </svg>

                        </button>
                        <button onClick={openMail} className="ButtonFooterContact"> <svg width="50" height="50" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M55 26.867V56.9181M55 56.9181L66.1111 44.8976M55 56.9181L43.8889 44.8976M5.15733 44.8976L45.1367 73.8465C48.7006 76.4171 50.4828 77.7021 52.4089 78.2016C54.1106 78.6427 55.885 78.6427 57.5872 78.2016C59.5133 77.7021 61.2956 76.4171 64.8594 73.8465L104.839 44.8976M45.545 9.24984L13.323 31.1419C10.2845 33.2063 8.76528 34.2385 7.66333 35.6338C6.68805 36.8688 5.95422 38.3048 5.50617 39.8552C5 41.6068 5 43.5476 5 47.4291V85.7673C5 92.4993 5 95.8656 6.21106 98.4368C7.27628 100.698 8.97606 102.538 11.0668 103.69C13.4436 105 16.555 105 22.7778 105H87.2222C93.445 105 96.5567 105 98.9333 103.69C101.024 102.538 102.724 100.698 103.789 98.4368C105 95.8656 105 92.4993 105 85.7673V47.4291C105 43.5476 105 41.6068 104.494 39.8552C104.046 38.3048 103.312 36.8688 102.337 35.6338C101.235 34.2385 99.7155 33.2063 96.6772 31.1419L64.455 9.24978C61.0256 6.92009 59.3111 5.75519 57.4717 5.30111C55.8444 4.89963 54.1556 4.89963 52.5283 5.30111C50.6889 5.75519 48.9744 6.92009 45.545 9.24984Z" stroke="#D4C74F" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                        <button onClick={openCV} className="ButtonFooterContact"><svg width="50" height="50" viewBox="0 0 82 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.9802 56.3487C33.8218 56.7394 33.6139 57.0767 33.3563 57.3586C33.1011 57.6449 32.7891 57.8668 32.4205 58.0266C32.0518 58.1864 31.6004 58.2641 31.0735 58.2641C30.3338 58.2641 29.7217 58.1132 29.2302 57.8135C28.7386 57.5095 28.3463 57.1122 28.0533 56.6128C27.7603 56.1179 27.5476 55.5542 27.4153 54.935C27.2806 54.3158 27.2167 53.6832 27.2167 53.044C27.2167 52.4026 27.2806 51.7745 27.4153 51.1509C27.5476 50.5272 27.7603 49.9679 28.0533 49.473C28.3463 48.9736 28.7386 48.5764 29.2302 48.2723C29.7217 47.9682 30.3361 47.8218 31.0735 47.8218C31.7139 47.8218 32.2409 47.935 32.6473 48.1636C33.0538 48.3966 33.3799 48.6629 33.6257 48.9648C33.8715 49.2688 34.0416 49.5707 34.1361 49.8836C34.2307 50.1921 34.2968 50.4384 34.3346 50.616H40.4033C40.1386 48.219 39.2453 46.368 37.7211 45.063C36.1992 43.758 34.0392 43.0944 31.2436 43.0767C29.6745 43.0767 28.2589 43.3141 27.0041 43.7957C25.7468 44.2729 24.6692 44.9498 23.7712 45.8199C22.8732 46.6899 22.1832 47.7352 21.7011 48.9625C21.219 50.1854 20.9756 51.5482 20.9756 53.0374C20.9756 54.4378 21.2048 55.7517 21.6562 56.9657C22.1099 58.1842 22.7787 59.2318 23.6578 60.1217C24.5369 61.0117 25.6074 61.7131 26.8741 62.228C28.1408 62.7384 29.5965 63.0003 31.2436 63.0003C32.7182 63.0003 34.0322 62.7673 35.1854 62.3079C36.3363 61.844 37.3052 61.2381 38.0897 60.4835C38.8743 59.7289 39.4698 58.8789 39.8763 57.9401C40.2828 57.0035 40.4884 56.0491 40.4884 55.0926H34.3606C34.2685 55.5364 34.1432 55.9603 33.9802 56.3487Z" fill="#D4C74F" />
                            <path d="M50.6689 55.068H50.6122L47.2943 43.5359H40.9988L47.0391 62.5518H54.0152L60.3414 43.5359H53.9301L50.6689 55.068Z" fill="#D4C74F" />
                            <path d="M66.7646 0H0V100H82V13.8735L66.7646 0ZM74.9105 93.3395H7.08954V6.65823H63.1111V17.2226H74.9081L74.9105 93.3395Z" fill="#D4C74F" />
                        </svg>
                        </button>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Section6
