import './Header.css';

import React from 'react'

const Header = () => {
    return (
        <>
            <div className='mobileHeader'><h1>Yazen hamoud <br></br> Portfolio</h1></div>
            <div className='headerClass' style={{ marginTop: 50 }}>
                <div className='menuLinks'>
                    <ul>
                        <li>
                            <span>Y</span>
                            <span>A</span>
                            <span>Z</span>
                            <span>E</span>
                            <span>N</span>
                        </li>
                        <li>
                            <span>H</span>
                            <span>A</span>
                            <span>M</span>
                            <span>O</span>
                            <span>U</span>
                            <span>D</span>
                        </li>
                        <li>
                            <span>-</span>
                        </li>
                        <li>
                            <span>I</span>
                            <span>S</span>
                            <span>R</span>
                            <span>A</span>
                            <span>E</span>
                            <span>L</span>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header
