import "./Section4.css"

import React from 'react'

const Section4 = () => {
    const openGitHubTabCollege = () => {
        window.open('https://github.com/hamoudyazen/college', '_blank');
    };

    return (
        <>



            <div className="FlexContainer4" onClick={openGitHubTabCollege}>

                <div className="section2CardHeader4">
                    <p>.01</p>
                    <p>Showcase</p>
                </div>

                <div className="section2CardContentr4">
                    <ul>
                        <li>
                            <span>C</span>
                            <span>O</span>
                            <span>L</span>
                            <span>L</span>
                            <span>E</span>
                            <span>G</span>
                            <span>E</span>
                        </li>
                    </ul>
                </div>

                <div className="section2CardFooter4">
                    <p>Launch Project</p>
                    <svg width="50" height="50" viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6987 93.9999C24.1646 91.3428 24.5827 88.2292 26.6325 87.0457L96.8513 46.5049L56.0149 29.7125C53.4806 28.6704 51.7221 25.6246 52.0868 22.9087C52.4514 20.1929 54.8011 18.8364 57.3354 19.8785L109.25 41.2257C110.467 41.7256 111.565 42.7239 112.301 43.9999C113.038 45.2758 113.354 46.7255 113.178 48.0295L105.708 103.663C105.343 106.378 102.994 107.735 100.46 106.693C97.9253 105.651 96.1665 102.604 96.5312 99.8887L102.407 56.1274L32.188 96.6683C30.1382 97.8517 27.2328 96.657 25.6987 93.9999Z" fill="black" />
                    </svg>

                </div>

            </div>
        </>
    )
}

export default Section4
