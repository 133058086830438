import { useEffect } from "react";
import "./Section3.css"


const Section3 = () => {


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            for (let i = 0; i < entries.length; i++) {
                const targetElement = entries[i].target;

                if (entries[i].isIntersecting) {
                    // Element is in view, add the entry animation class
                    if (targetElement.classList.contains('CardOne')) {
                        targetElement.style.animationName = 'showCardOneAnim';
                    } else if (targetElement.classList.contains('CardTwo')) {
                        targetElement.style.animationName = 'showCardAnim';
                    }

                    targetElement.style.animationDuration = '1s'; // Set the duration as needed
                    targetElement.style.animationTimingFunction = 'ease'; // Set the timing function as needed
                } else {
                    // Element is not in view, add the exit animation class
                    if (targetElement.classList.contains('CardOne')) {
                        targetElement.style.animationName = 'UnshowCardOneAnim';
                    } else if (targetElement.classList.contains('CardTwo')) {
                        targetElement.style.animationName = 'UnshowCardAnim';
                    }

                    // You can also set animation duration and timing for the exit animation
                    targetElement.style.animationDuration = '1s'; // Set the exit animation duration as needed
                    targetElement.style.animationTimingFunction = 'ease'; // Set the exit animation timing function as needed
                }
            }
        });

        // Specify the elements to observe
        const cardElements = document.querySelectorAll('.CardOne, .CardTwo');

        cardElements.forEach((element) => {
            observer.observe(element);
        });
    }, []);




    return (
        <div className='CardsContainer'>
            <div className="TwoCardsContainer">
                <div className='CardOne'>
                    <div className='CardOneDiv1'>
                        <svg width="50" height="50" viewBox="0 0 142 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="142" height="137" rx="30" fill="#A4A1FE" />
                            <path d="M76.1195 105.2C72.0529 105.2 68.2862 104.433 64.8195 102.9C61.3529 101.367 58.3195 99.1667 55.7195 96.3C53.1195 93.3667 51.0862 89.8 49.6195 85.6C48.2195 81.3333 47.5195 76.5333 47.5195 71.2C47.5195 64.2 48.7862 58.1667 51.3195 53.1C53.8529 47.9667 57.3195 44.0333 61.7195 41.3C66.1195 38.5667 71.1862 37.2 76.9195 37.2C80.8529 37.2 84.3529 38.0333 87.4195 39.7C90.4862 41.3 92.9529 43.1333 94.8195 45.2L90.1195 50.5C88.4529 48.6333 86.5195 47.1667 84.3195 46.1C82.1195 45.0333 79.6529 44.5 76.9195 44.5C72.7195 44.5 69.0529 45.6 65.9195 47.8C62.8529 49.9333 60.4529 52.9667 58.7195 56.9C56.9862 60.8333 56.1195 65.5333 56.1195 71C56.1195 76.5333 56.9862 81.3 58.7195 85.3C60.4529 89.3 62.8529 92.4 65.9195 94.6C69.0529 96.8 72.7195 97.9 76.9195 97.9C79.9195 97.9 82.6195 97.2667 85.0195 96C87.4195 94.7333 89.6529 92.9333 91.7195 90.6L96.4195 95.7C93.8862 98.7 90.9529 101.033 87.6195 102.7C84.2862 104.367 80.4529 105.2 76.1195 105.2Z" fill="#1B1B1F" />
                        </svg>
                    </div>
                    <div className='CardOneDiv2'>
                        <h2>Craft CMS</h2>
                        <p>I'm focused on developing, from start to finish, web solutions with Craft CMS, an easy to use content management system where admins can alter or add content to a website.</p>
                    </div>
                </div>
                <div className='CardTwo'>
                    <p>I'm an expert in</p>
                    <h2>Craft CMS</h2>
                </div>
            </div>




            <div className='TwoCardsContainer'>
                <div className='CardOne'>
                    <div className='CardOneDiv1'>
                        <svg width="50" height="50" viewBox="0 0 142 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="142" height="137" rx="30" fill="#A4A1FE" />
                            <path d="M76.1195 105.2C72.0529 105.2 68.2862 104.433 64.8195 102.9C61.3529 101.367 58.3195 99.1667 55.7195 96.3C53.1195 93.3667 51.0862 89.8 49.6195 85.6C48.2195 81.3333 47.5195 76.5333 47.5195 71.2C47.5195 64.2 48.7862 58.1667 51.3195 53.1C53.8529 47.9667 57.3195 44.0333 61.7195 41.3C66.1195 38.5667 71.1862 37.2 76.9195 37.2C80.8529 37.2 84.3529 38.0333 87.4195 39.7C90.4862 41.3 92.9529 43.1333 94.8195 45.2L90.1195 50.5C88.4529 48.6333 86.5195 47.1667 84.3195 46.1C82.1195 45.0333 79.6529 44.5 76.9195 44.5C72.7195 44.5 69.0529 45.6 65.9195 47.8C62.8529 49.9333 60.4529 52.9667 58.7195 56.9C56.9862 60.8333 56.1195 65.5333 56.1195 71C56.1195 76.5333 56.9862 81.3 58.7195 85.3C60.4529 89.3 62.8529 92.4 65.9195 94.6C69.0529 96.8 72.7195 97.9 76.9195 97.9C79.9195 97.9 82.6195 97.2667 85.0195 96C87.4195 94.7333 89.6529 92.9333 91.7195 90.6L96.4195 95.7C93.8862 98.7 90.9529 101.033 87.6195 102.7C84.2862 104.367 80.4529 105.2 76.1195 105.2Z" fill="#1B1B1F" />
                        </svg>
                    </div>
                    <div className='CardOneDiv2'>
                        <h2>Fast Support</h2>
                        <p>I aim to be as available and supportive as I can to my clients. Feel free to use the form below or contact me directly with questions or requests.</p>
                    </div>
                </div>
                <div className='CardTwo'>
                    <p>I am always</p>
                    <h2>happy to help</h2>
                </div>
            </div>



            <div className='TwoCardsContainer'>
                <div className='CardOne'>
                    <div className='CardOneDiv1'>
                        <svg width="50" height="50" viewBox="0 0 142 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="142" height="137" rx="30" fill="#A4A1FE" />
                            <path d="M76.1195 105.2C72.0529 105.2 68.2862 104.433 64.8195 102.9C61.3529 101.367 58.3195 99.1667 55.7195 96.3C53.1195 93.3667 51.0862 89.8 49.6195 85.6C48.2195 81.3333 47.5195 76.5333 47.5195 71.2C47.5195 64.2 48.7862 58.1667 51.3195 53.1C53.8529 47.9667 57.3195 44.0333 61.7195 41.3C66.1195 38.5667 71.1862 37.2 76.9195 37.2C80.8529 37.2 84.3529 38.0333 87.4195 39.7C90.4862 41.3 92.9529 43.1333 94.8195 45.2L90.1195 50.5C88.4529 48.6333 86.5195 47.1667 84.3195 46.1C82.1195 45.0333 79.6529 44.5 76.9195 44.5C72.7195 44.5 69.0529 45.6 65.9195 47.8C62.8529 49.9333 60.4529 52.9667 58.7195 56.9C56.9862 60.8333 56.1195 65.5333 56.1195 71C56.1195 76.5333 56.9862 81.3 58.7195 85.3C60.4529 89.3 62.8529 92.4 65.9195 94.6C69.0529 96.8 72.7195 97.9 76.9195 97.9C79.9195 97.9 82.6195 97.2667 85.0195 96C87.4195 94.7333 89.6529 92.9333 91.7195 90.6L96.4195 95.7C93.8862 98.7 90.9529 101.033 87.6195 102.7C84.2862 104.367 80.4529 105.2 76.1195 105.2Z" fill="#1B1B1F" />
                        </svg>
                    </div>
                    <div className='CardOneDiv2'>
                        <h2>Custom designs</h2>
                        <p>I'm skilled at customizing existing designs,
                            as well as producing stunning, award-winning brand-new designs.</p>
                    </div>
                </div>
                <div className='CardTwo'>
                    <p>Flexible Design</p>
                    <h2>Use Your Imagination</h2>
                </div>
            </div>



            <div className='TwoCardsContainer'>
                <div className='CardOne'>
                    <div className='CardOneDiv1'>
                        <svg width="50" height="50" viewBox="0 0 142 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="142" height="137" rx="30" fill="#A4A1FE" />
                            <path d="M76.1195 105.2C72.0529 105.2 68.2862 104.433 64.8195 102.9C61.3529 101.367 58.3195 99.1667 55.7195 96.3C53.1195 93.3667 51.0862 89.8 49.6195 85.6C48.2195 81.3333 47.5195 76.5333 47.5195 71.2C47.5195 64.2 48.7862 58.1667 51.3195 53.1C53.8529 47.9667 57.3195 44.0333 61.7195 41.3C66.1195 38.5667 71.1862 37.2 76.9195 37.2C80.8529 37.2 84.3529 38.0333 87.4195 39.7C90.4862 41.3 92.9529 43.1333 94.8195 45.2L90.1195 50.5C88.4529 48.6333 86.5195 47.1667 84.3195 46.1C82.1195 45.0333 79.6529 44.5 76.9195 44.5C72.7195 44.5 69.0529 45.6 65.9195 47.8C62.8529 49.9333 60.4529 52.9667 58.7195 56.9C56.9862 60.8333 56.1195 65.5333 56.1195 71C56.1195 76.5333 56.9862 81.3 58.7195 85.3C60.4529 89.3 62.8529 92.4 65.9195 94.6C69.0529 96.8 72.7195 97.9 76.9195 97.9C79.9195 97.9 82.6195 97.2667 85.0195 96C87.4195 94.7333 89.6529 92.9333 91.7195 90.6L96.4195 95.7C93.8862 98.7 90.9529 101.033 87.6195 102.7C84.2862 104.367 80.4529 105.2 76.1195 105.2Z" fill="#1B1B1F" />
                        </svg>
                    </div>
                    <div className='CardOneDiv2'>
                        <h2>SEO</h2>
                        <p>Using my expertise along with handy tools we strive for a high SEO score, ensuring higher visibility on search sites such as Google.</p>
                    </div>
                </div>
                <div className='CardTwo'>
                    <p>With my SEO knowledge</p>
                    <h2>you will be seen</h2>
                </div>
            </div>
        </div>
    )
}

export default Section3
