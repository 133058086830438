import React from 'react'
import Header from './components/Header'
import "./App.css"
import Section2 from './components/Section2'
import Section3 from './components/Section3'
import Section4 from './components/Section4'
import Section5 from './components/Section5'
import Section6 from './components/Section6'
import Pageone from './components/Pageone'


const App = () => {
  return (
    <div>
      <Header></Header>
      <Pageone></Pageone>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
      <Section6></Section6>
    </div>
  )
}

export default App
